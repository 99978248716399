import Box from "@mui/material/Box";
// Router
import Routes from "../router";

//function Layout()
export default function Layout() {
  console.log("----------isAuth----- Layout");

  return (
    <>
      {/* <Box sx={{ display: "flex" }}>
        <Box className="header-section" sx={{ display: "flex" }}>
        </Box>
        <Box className="navbar-section" sx={{ display: "flex" }}>
        </Box>
        <Box className="container-section" sx={{ display: "flex" }}>
          <Routes />
        </Box>
        <Box className="footer-section" sx={{ display: "flex" }}>
        </Box>
      </Box> */}
    </>
  );
}